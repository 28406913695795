/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //Shrink Header
        // When the user scrolls down 50px from the top of the document, resize the header's font size

        $(document).on("scroll", function(){
          if ($(document).scrollTop() > 100){
            $("#navbar-main").addClass("small");
          } else {
            $("#navbar-main").removeClass("small");
          }
        });
        $(document).on("scroll", function(){
          if ($(document).scrollTop() > 125){
            $(".albumArtwork").addClass("small");
          } else {
            $(".albumArtwork").removeClass("small");
          }
        });
        $(document).on("scroll", function(){
          if ($(document).scrollTop() > 100){
            $(".siteSkin").addClass("small");
          } else {
            $(".siteSkin").removeClass("small");
          }
        });
        // Toggle Mini calendar
        $("a.mobileIcon").on('click', function(){
          $("section.tribe-mini-calendar-2").slideToggle('slow');
        });
        $("h4.filterToggle").on('click', function(){
          $(".tribe_events_filter_item.closed").toggleClass('show');
        });
        // Off-canvas menu toggle
        $('[data-toggle="offcanvas"], #navToggle').on('click', function () {
          $('.offcanvas-collapse').toggleClass('open');
        });
        $('.dropdown').hover(function() {
          $(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();

          }, function() {
          $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();

          });
          $('.navbar .dropdown > a').click(function(){
            location.href = this.href;
            });
      // Toggle Contest Rules
          $('a.contestBtn').on('click', function(){
            $('.contestRules').slideToggle(1000);
          });

          $('.dropdown > a').click(function(){
          location.href = this.href;
          });
          $('a.playBtn').on('click', function(){
            $('.pause').toggle('1000');
            $('.play').toggleClass('hide');

          });
        // // Toggle Mailchimp
        // let content = document.querySelector('#newsletterPop');
        // let popup = document.querySelector('.popup');
        // let button = document.querySelector('.newsletterBtn');
        //
        // button.onclick = () => {
        //   popup.style.display = 'block';
        // }
        //
        // window.onclick = e => {
        //   if (e.target === popup) {
        //     popup.style.display = 'none';
        //   }
        // }

        // Toggle Weather Forecast
        $("img.city-icon").on('click', function() {
          $("#openweathermap-widget-19").toggleClass('show');
        });
        // HTML wrappers ----------//
        //$(".entry-content iframe").wrap("<div class='embed-container'/>");
        $(".listing iframe").wrap("<div class='embed-container' />");
        $(".vidFull iframe").wrap("<div class='embed-container' />");
        $(".entry-content iframe").wrap("<div class='embed-container' />");
        $(".entry-content iframe.liveFeed").wrap("<div class='embed-container-live' />");
        $(".entry-content.podcast iframe").wrap();
        // $(".entry-content iframe.regularContent").unwrap("<div class='embed-container' />");


        //------Site Skin----//
        $('.siteSkin').mouseover(function(){
          $('.siteSkin').addClass('show');
          $('.siteSkin').mouseout(function(){
            $('.siteSkin').removeClass('show');
          });
        });
        $('.skinTop').on('click', function(){
          $('.skin').toggleClass('show');
          });

        //*============== AJAX CATEGORY ===================*//

        // jQuery(function($){
          	$('#filter').submit(function(){

          		var filter = $('#filter');
          		$.ajax({
          			url:filter.attr('action'),
          			data:filter.serialize(), // form data
          			type:filter.attr('method'), // POST
          			beforeSend:function(xhr){
          				filter.find('button').text('Processing...'); // changing the button label
          			},
          			success:function(data){
          				filter.find('button').text('Apply filter'); // changing the button label back

                  $('#response').html(data); // insert data

                }
              	});
              		return false;
               	});

        //*==========Extract Link Text==========*/
        $('.article a').addClass(function() {
            return $(this).text();
          });
          $('.tribe-events-website a[href^="https"]').attr('target', function() {
              if(this.host == location.host) return '_self';
              else return '_blank';
            });

      //*==========OWL CAROUSEL==========*/

        // Carousels ----------//
        $(".owl-carousel.home").owlCarousel({
        // Navigation
        nav : true,
        // loop: ( $('.owl-carousel .items').length > 5 ),
        loop: true,
        dots: false,
        // touchDrag: true,
        responsiveClass:true,
        lazyLoad: true,
        navText: ['<span class="fas fa-chevron-left fa-2x"></span>','<span class="fas fa-chevron-right fa-2x"></span>'],
        responsive : {
            // breakpoint from 0 up
            0 : {
                items : 1.5,
                margin : 3
            },
            // breakpoint from 480 up
            480 : {
              items : 1.5,
              margin : 10
            },
            // breakpoint from 768 up
            768 : {
              items : 2.5,
              margin : 10
            },
            // breakpoint from 992 up
            1024 : {
              items : 4,
              margin : 10
            }
        }
      });
        $(".owl-carousel").owlCarousel({
          // Navigation
          nav : true,
          loop: ( $('.owl-carousel .items').length > 5 ),
          dots: false,
          responsiveClass:true,
          //touchDrag: true,
          lazyLoad: true,
	        navText: ['<span class="fas fa-chevron-left fa-2x"></span>','<span class="fas fa-chevron-right fa-2x"></span>'],
          responsive : {
              // breakpoint from 0 up
              0 : {
                  items : 1,
                  margin : 0
              },
              // breakpoint from 480 up
              480 : {
                items : 2,
                margin : 10
              },
              // breakpoint from 768 up
              768 : {
                items : 3,
                margin : 10
              },
              // breakpoint from 992 up
              992 : {
                items : 5,
                margin : 10
              }
          }
        });

        $("#bizListing").owlCarousel({
          // Navigation
          autoplay:true,
           autoplayTimeout:1000,
           autoplayHoverPause:true,
           loop: true,
           responsiveClass:true,
           items: 12,
           lazyLoad: true,
          nav : true,
          dots: false,
          touchDrag: true,
          navText: ['<span class="fas fa-chevron-left fa-2x"></span>','<span class="fas fa-chevron-right fa-2x"></span>'],
          responsive : {
              // breakpoint from 0 up
              0 : {
                  items : 1,
                  margin : 0
              },
              // breakpoint from 480 up
              480 : {
                items : 2,
                margin : 10
              },
              // breakpoint from 768 up
              768 : {
                items : 3,
                margin : 10
              },
              // breakpoint from 992 up
              992 : {
                items : 5,
                margin : 10
              }
          }
        });

      //*===========Iframe Customization - Neighbors===========*/
      $(document).ready(function() {
      // Update Height of Hub Now and on Resize
      updateHubHeight();
      $(window).on('resize', updateHubHeight);
      });
      function updateHubHeight() {
      var menuHeight = 100;
      $('#myHub').css('height', $(window).height() - menuHeight );
    }
      //*============Change Post CSS================*/

          $(".futuri-widget iframe").addClass("widthFull");
          $('iframe.widthFull').css('max-width', '1300px');
        // Prevent dropdown from closing when a link is clicked ----------//
        $('#menu-primary-menu .dropdown-menu').on('click', function(event) {
          event.stopPropagation();
        });

        // Prevent evet heading link from navigating  ----------//
        $('.tribe-events-filters-group-heading').on('click', function(event) {
          event.preventDefault();
        });

        // var random = Math.floor(Math.random() * $('.ad').length);
        //     $('.ad').hide().eq(random).show();

            googletag.cmd.push(function() {
          // This will only register the slot.
          // Ad will be fetched only when refresh is called.
          googletag.pubads().refresh();
        });
},
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },


    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
  }
};
//*==============INSERT ADS INTO CALENDAR================*//


  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/* Custom JavaScript Area */
$(window).scroll(function(){
  var myMenu = document.getElementById('events-menu');
  var myElement = document.getElementById('article-content');
  if(myMenu && myElement){
    var bounding = myElement.getBoundingClientRect();
    var myElementHeight = myElement.offsetHeight;
    var myElementWidth = myElement.offsetWidth;
    if (bounding.top >= -myElementHeight
        && bounding.left >= -myElementWidth
        && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + myElementWidth
        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight) {
        myMenu.style.opacity="1";
    } else {
        myMenu.style.opacity="0";
    }
  }
});
